<template>
    <div>
        <el-form :inline="true" v-if="hasAuth('sys:insurancetype:add')">
            <el-form-item>
                <el-button type="primary" @click="saveButton(null)" v-if="hasAuth('sys:insurancetype:add')">新增</el-button>
            </el-form-item>
        </el-form>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe>
            <el-table-column prop="insuredValue" align="center" label="保值（万元）">
            </el-table-column>
            <el-table-column prop="insurancePremium" align="center" label="保费（元）">
            </el-table-column>
            <el-table-column prop="icon" align="center" width="300" label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain v-if="hasAuth('sys:insurancetype:update')"
                        @click="saveButton(scope.row.insuranceTypeId)">编辑</el-button>
                    <template v-if="hasAuth('sys:insurancetype:delete')">
                        <el-popconfirm title="确定要删除该条数据吗？" @confirm="delHandle(scope.row.insuranceTypeId)">
                            <el-button type="danger" plain slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <saveInsuranceType ref="saveInsuranceType" v-if="saveInsuranceTypevisible" @refreshDataList="getCustomerservice">
        </saveInsuranceType>
    </div>
</template>

<script>
import saveInsuranceType from "./saveInsuranceType";
export default {
    name: "VehicletypeList",
    components: {
        saveInsuranceType
    },
    data() {
        return {
            tableData: [],
            saveInsuranceTypevisible: false,
        }
    },
    created() {
        this.getCustomerservice();
    },
    methods: {
        getCustomerservice() {
            this.$axios.post("/admin/insurancetype/getInsuranceTypeList").then(res => {
                this.tableData = res.data
            })
        },
        delHandle(id) {
            this.$axios.post("/admin/insurancetype/delInsuranceType", { insuranceTypeId: id }).then(res => {
                this.$message({
                    showClose: true,
                    message: '恭喜你，操作成功',
                    type: 'success',
                    onClose: () => {
                        this.getCustomerservice()
                    }
                });
            })
        },
        saveButton(insuranceTypeId) {
            this.saveInsuranceTypevisible = true;
            this.$nextTick(() => {
                this.$refs.saveInsuranceType.init(insuranceTypeId);
            })
        },
    }
}
</script>

<style scoped>
.el-button {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    margin-right: 10px;
}
</style>

